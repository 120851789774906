import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import SEO from "../components/seo";

const Features = () => {
  const {
    FeaturesTop: {
      frontmatter: { title, discription, image },
    },
    Features: { edges },
  } = useStaticQuery(graphql`
    query Features {
      FeaturesTop: markdownRemark(
        fileAbsolutePath: { regex: "/features_page/" }
      ) {
        frontmatter {
          title
          discription
          image {
            publicURL
          }
        }
      }
      Features: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/core_features/" } }
        sort: { fields: [frontmatter___id], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              id
              title
              path
              long_description
              image {
                publicURL
              }
              icons {
                icon {
                  publicURL
                }
                include
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        seo={{
          title: "Merlin Panel Features",
          description:
            "We’ve worked hard to build a robust set of features that every client has access to depending on which package they purchase.",
          previewURL: "https://merlinpanel.com/images/features/preview.png",
        }}
      />
      <div className="max-w-6xl mx-auto mt-20 py-12 px-5 md:px-8">
        <div className="flex flex-col items-center w-full mt-20">
          <div className="w-2/3 flex justify-center">
            <img alt={`${title}-icon`} src={image.publicURL}></img>
          </div>

          <h3 className="text-3xl md:text-5xl text-blackish mt-10 font-bold">
            {title}
          </h3>
          <p className="text-lg text-textcolor mt-6 w-full md:w-3/4 text-center">
            {discription}
          </p>
        </div>
        <div className="flex flex-wrap justify-between w-full my-12 md:my-24">
          {edges &&
            edges.map((item, index) => {
              const {
                title,
                long_description,
                image,
                icons,
                path,
              } = item.node.frontmatter;
              return (
                <div key={index} className="w-full md:w-1/2 xl:w-1/3 mb-24">
                  <Link to={path}>
                    <div className="w-full md:w-11/12 feature-overview-card px-5 md:px-8 py-12 h-full">
                      <div className="flex flex-col items-center justify-between h-full">
                        <div className="w-3/5 flex justify-center">
                          <img alt={`${title}-preview`} src={image.publicURL} />
                        </div>
                        <h4 className="text-2xl text-theme font-bold  text-center">
                          {title}
                        </h4>
                        <p className=" text-textcolor text-md  text-center px-4">
                          {long_description}
                        </p>
                        <div className="flex w-1/3 justify-between">
                          {icons.map((iconobject) => {
                            return (
                              <div className=" w-1/4">
                                <img
                                  className={`${
                                    iconobject.include
                                      ? "opacity-100"
                                      : "opacity-25"
                                  }`}
                                  src={iconobject.icon.publicURL}
                                ></img>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default Features;
